@import "../../styles/variables.scss";
@import "../../styles/media.scss";

// .contact-us-page * {
// 	&:hover {
// 		outline: dashed 1px magenta;
// 		outline-offset: -1px;
// 	}
// }
.contact-us-page {
	font-size: 1.4rem;

	& h1 {
		font-size: 2.4em;
	}
	& h4 {
		font-size: 1.1em;
		letter-spacing: -1px;
	}
	& .contact-details-box {
		margin-top: 20px;
		& .contact-detail-box {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 10px;
			& .icon {
				fill: $dark-pink;
				height: 45px;
				width: 45px;
				margin: 10px 15px 13px 0;
				@media ($mobile-l) {
					height: 25px;
					width: 25px;
				}
				& svg {
					height: 100%;
					width: 100%;
				}
			}
			& .contact-detail-text-box {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				& h3 {
					font-weight: 600;
					font-family: sans-serif;
					font-size: 1.5em;
					letter-spacing:-0.5px;
					margin: 0;
					line-height: 1.2em;
					& span {
						font-weight: 100;
						color: $light-grey;
						font-size: .9em;
					}
				}



				& p {
					font-size: 1.2em;
					line-height: 1.4em;
					margin: 0;
					padding: 0;
					@media ($desktop-s) {
						font-size: 1.2em;
					}
					@media ($mobile-l) {
						font-size: 1.1em;
					}
				}
				& a.link-only, & a.link-only:visited {
					color: $mid-grey;
					text-decoration: none;
					display:block;

				}
				& a.link-only:hover {
					color: $solid-gold;
					text-decoration: underline;
				}
			}
		}
	}

}