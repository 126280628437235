
/*
Key:	cc1d50
		rgb(204, 29, 80)
*/
$deep-plum:				rgb(105, 13, 39);				//	cc1d50
$solid-plum:			rgb(136, 26, 57);				//	cc1d50
$dark-pink:				rgb(204, 29, 80);				//	cc1d50
$lift-pink:				rgb(214, 30, 84);				/* Lifts brightness for use on white background to make it look the same */
$bold-pink:				rgb(221, 18, 113);
$alpha-pink:			rgba(221, 18, 113, 0.05);
$solid-black:			rgb(0,0,0);
$solid-white:			rgb(255, 255, 255);
$alpha-white-01:		rgba(255, 255, 255, 0.1);
$alpha-white-02:		rgba(255, 255, 255, 0.2);
$alpha-white-03:		rgba(255, 255, 255, 0.3);
$alpha-white-04:		rgba(255, 255, 255, 0.4);
$alpha-white-06:		rgba(255, 255, 255, 0.6);
$alpha-white-08:		rgba(255, 255, 255, 0.8);
$bright-gold:			rgb(255, 208, 0);
$solid-gold:			rgb(201, 171, 0);
$blue-slate:			rgb(45, 63, 86);
$blue-sea:				rgb(46, 84, 134);
$soft-sky:				rgb(167, 205, 223);
$solid-sky:				rgb(0, 204, 255);


$teaser-column-fill: 	rgb(255, 255, 255);
$ultra-light-grey: 		#F2F2F2;
$light-grey: 			rgb(197, 197, 197);
$mid-grey: 				rgb(130, 130, 130);
$slate-grey: 			#474A4D;
$dark-slate-grey: 		#3A3B3D;
$warm-black: 			#181415;

$warning-text:			#ee6644;

$default-ease:			0.5s;

$container-max-width:	1140px;
$showcase-max-width:	1220px;

$header-height:			150px;
$header-height--small:	80px;				 // zero as menu inside header for Avstar

$nav-height:			60px;
$nav-height--small:		38px;

// This set varies by nav style used. See nothes in App.scss => .main "Complex triggers of SCROLLING and PAGE-WIDTH affect main top margin"
$main-top-normal:		$header-height;
$main-top-small:		$header-height;
$main-top-mobile:		$header-height--small;





$logo-w-over-h:			1;
$page-max-width:		1100px;

$hamburger-menu-bars:	$lift-pink;
$hamburger-menu-panel:	$lift-pink;
// see $nav-burger in media.scss