@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.container {

	& .cards {
		display: flex;
		flex: 0 1 auto;
		font-size: 1.2rem;
		flex-direction: column;
		justify-content: flex-start;
		& .card {
			display: flex;
			flex: 0 1 auto;
			flex-direction: row;
			padding: 20px 40px 10px 40px;
			// &:first-child {
			// 	padding: 30px 40px 10px 20px;
			// }
			// &:last-child {
			// 	padding: 20px 40px 10px 20px;
			// 	margin-bottom: 15px;
			// }
			& .icon-teaser {
				fill: $blue-sea;
				width: 3rem;
				height: 3rem;
				margin: 0 20px 20px 0;
			}
			& .text-box h2 {
				text-transform: uppercase;
				font-weight: normal;
			}
			&:hover {
				box-shadow: 5px 10px 30px rgba(200, 200, 200, .2);
				z-index: 10;
				& .icon-teaser {
					fill: $solid-gold;
				}
			}

		}
		&.cards-h {
			max-width: 100vw;
		}
		/* Fully columnated (two columns) */
		@media (min-width: 48em) {
			&.cards-h {
				max-width: 100vw;
				flex-direction: row;
				flex-wrap: wrap;
				.card {
					max-width: 50%;
				}
			}
		}
	}
}