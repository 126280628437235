@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.brand-box {
		// outline: dashed 2px magenta;
		// outline-offset: -2px;
	display: flex;
	flex: 0 1 auto;
	justify-content: flex-start;
	align-items: center;
	.header-item & {
		height: 100%;
	}
	&.brand-vertical {
		flex-direction: column;
	}

	/* ADJUST for Footer usage */
	@media ($desktop-m) {
		.footer & {
			flex-direction: column;
		}
	}
}

.brand-logo {
		// outline: dashed 2px lime;
		// outline-offset: -2px;

	/* Default (large) */
	height: 40vmin;
	margin: 30px 30px 30px 0;
	fill: $lift-pink;
	transition: width $default-ease ease, height $default-ease ease, fill $default-ease ease;
	& .logo-S, & .logo-TM {
		transition: opacity $default-ease ease;
		opacity: 0.3;
	}
	& .logo-W {
		transition: opacity $default-ease ease;
		opacity: 0.4;
	}


	/* ADJUST for Header usage (with header--small transition) */
	.header-item--logo & {
		height: calc( #{$header-height} - 72px );
		margin: 0 10px 0 0;
		.header--small & {
			height: 48px;
			fill: $solid-white;
			transition: box-shadow $default-ease ease, padding $default-ease ease, background-color $default-ease ease, fill $default-ease ease, font-size $default-ease ease, width $default-ease ease, height $default-ease ease;
			& .logo-S, & .logo-W, & .logo-TM {
				transition: opacity $default-ease ease;
				opacity: 0.5;
			}
		}
	}

	/* ADJUST for Home Panel usage */
	.home-panel & {
		max-height: 220px;
		margin: 0 0 20px 0;
	}

	/* ADJUST for Footer usage */
	.footer & {
		height: 80px;
		fill: $solid-white;
		& .logo-S, & .logo-W, & .logo-TM {
			opacity: 0.45;
		}
	}
	@media ($desktop-m) {
		margin: 0 0 30px 0;
	}

	/* Generic mutations - classed above individual items */
	.black & {
		fill: $solid-black;
	}
	.reversed & {
		fill: $solid-white;
		& .logo-S, & .logo-W, & .logo-TM {
			opacity: 0.4;
		}
	}
}

.brand-masthead {
		// outline: dashed 2px rgb(0, 174, 255);
		// outline-offset: -2px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	fill: $solid-black;
	font-family: 'Montserrat', sans-serif;
	font-size: 3.0rem; /* drives em in .brand-masthead-primary & .brand-masthead-secondary */
	font-weight: 300;
	height: calc( #{$header-height} - 70px );
	transition: height $default-ease ease, margin-top $default-ease ease, fill $default-ease ease, color ease $default-ease, font-size ease $default-ease;


	/* ADJUST for Header usage */
	.header-item > a &,
	.header-item > a:visited & {
		color: $solid-black;
		.header--small & {
			color: $alpha-white-08;
			font-size: 2.4rem;
			@media ($mobile-l) {
				padding-top: 7px;
				font-size: 2.0rem;
			}
			@media ($mobile-m) {
				padding-top: 15px;
				font-size: 1.6rem;
			}
		}
	}
	.header--small .header-item--logo & {
		height: calc( #{$header-height--small} - 25px );
		fill: $solid-white;
		color: $solid-white;
	}


	@media (max-width: 19rem) {
		.header--small .header-item & {
			display: none;
		}
	}

	/* ADJUST for Home Panel usage */
	.home-panel & {
		font-size: 3.2rem; /* drives em in .brand-masthead-primary & .brand-masthead-secondary */
		@media ($tablet) {
			font-size: 2.6rem;
		}
		@media ($mobile-m) {
			font-size: 2.3rem;
			min-width: 220px;
		}
		@media ($mobile-s) {
			font-size: 2.0rem;
			text-align: center;
		}
	}

	/* ADJUST for Footer usage */
	.footer & {
		height: 80px;
		fill: $solid-white;
		color: $solid-white;
		@media ($mobile-l) {
			font-size: 2.1rem;
			min-width: 220px;
		}
	}

	/* Generic mutations - classed above individual items */
	.reversed & {
		fill: $solid-white;
		color: $solid-white;
	}
}

.brand-masthead-primary {
		// outline: dashed 2px rgb(200, 255, 0);
		// outline-offset: -2px;
	max-height: 4.3rem; 					/* allows compression of box to bring sub title closer */
	text-transform: uppercase;
	transition: max-height $default-ease ease;
	line-height: 1em;

	/* ADJUST for Header usage */
	.header--small .header-item & {
		max-height: 3rem; 					/* allows compression of box to bring sub title closer */
	}

	/* ADJUST for Footer usage */
	.footer & {
		max-height: 3.6rem; 					/* allows compression of box to bring sub title closer */
	}
}

.brand-masthead-secondary {
		// outline: dashed 2px rgb(0, 255, 179);
		// outline-offset: -2px;
	margin-right: 2px;
	align-self: center;
	transition: max-height $default-ease ease;
	font-weight: 400;
	font-size: .63em; /* em is from .brand-masthead */

	/* ADJUST for Header usage */
	.header--small .header-item & {
		transition: max-height $default-ease ease;
	}

}