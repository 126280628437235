@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.showcase {
	// outline: solid 3px magenta;
	// outline-offset: -3px;
	font-size: 1.2rem;
	max-width: $showcase-max-width;
	& div.showcase-panel {
		// outline: solid 30px rgba(200,200,200,.1);
		// outline-offset: -30px;
		background-color: white;
		width: 50%;
		cursor: pointer;
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		padding: 30px 40px 40px 40px;
		& h2 {
			text-transform: uppercase;
			font-size: 1.4em;
			font-weight: 400;
			& span {
				font-weight: 900;
			}
		}
		& p, a {
			font-size: 1.2em;
			line-height: 1.4em;
		}
		& a {
			color: $dark-pink;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
		& .icon-teaser {
			fill: $dark-pink;
			width: 5rem;
			height: 5rem;
			margin: 0 0 20px 0;
		}
		&:hover {
			box-shadow: 5px 10px 30px rgba(200, 200, 200, .4);
			z-index: 10;
		}
	}

	@media ($desktop-l) {
		& div.showcase-panel {
			& h2 {
				font-size: 1.3em;
			}
		}
	}

	/* Fully columnated with 1 panel per row. */
	@media ($tablet) {
		& div.showcase-panel {
			margin: 5px 0;
			width: 100%;
		}
	}
}
