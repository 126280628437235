@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.cta {
	font-family: Montserrat, sans-serif;
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	cursor: pointer;
	align-self: center;
	background: $solid-gold;
	&:hover {
		background: $dark-pink;
	}
	&.cta--blue-gold {
		background: $blue-slate;
		color: #FFFFFF;
		&:hover {
			background: $solid-gold;
			color: $blue-slate;
		}
	}
	&.cta--gold-blue {
		background: $blue-slate;
		background: $solid-gold;
		color: $blue-slate;
		&:hover {
			background: $blue-slate;
			color: #FFFFFF;
		}
	}
	&--right {
		align-self: flex-end;
		@media ($mobile-l) {
			align-self: center;
		}
	}
	&--left {
		align-self: flex-start;
		@media ($mobile-l) {
			align-self: center;
		}
	}
	font-size: 1.4rem;
	@media ($tablet) {
		font-size: 1.0em;
	}
	@media ($mobile-h) {
		font-size: 0.8em;
	}
	@media ($mobile-m) {
		font-size: 0.7em;
	}

	& a.cta--link,
	& a.cta--link:visited,
	& a.cta--link:hover {
		text-decoration: none;
		color: #FFFFFF;
		padding: .9em 2.2em;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		&:first-child {
			padding: .9em .15em .9em 2.2em;
		}
		&:last-child {
			padding: .9em 2.2em .9em .15em;
		}
		&:only-child {
			padding: .9em 2.2em .9em 2.2em;
		}
	}
	cursor: pointer;
}
