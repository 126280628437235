@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.associations-carousel.carousel-box {
	width: 100%;
	max-width: 1140px;
	margin: auto;
	padding: 50px 0;
}
.associations-carousel .BrainhubCarouselItem {
	& IMG {
		max-height: 80px;
		max-width: 100%;
		position: relative;
		// 	outline: 2px solid magenta;
		// 	outline-offset: -2px;
		// filter: grayscale(100%);				/* filter not active to pale grey */
		// opacity: 0.4;						/* filter not active to pale grey */
	}
	// &--active IMG {							/* filter not active to pale grey */
	// 	filter: none;							/* filter not active to pale grey */
	// 	opacity: 1;								/* filter not active to pale grey */
	// }
}
.associations-carousel .carousel-item {
	height: 80px;
	width: 150px;						/* This (in with Associations.js...spp(slidesPerPage)) controls the padding between each item */
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	& a {
		width: 100%;
	}

}

