@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.testimonials-carousel {
	&.carousel-box {
		width: 100%;
		max-width: 1140px;
		margin: auto;
		padding: 10px 0 50px 0;
	}
	& .BrainhubCarouselItem IMG {
		//max-height: 120px;
		max-width: 100%;
		position: relative;
		// outline: 2px solid magenta;
		// outline-offset: -2px;
	}
	& .carousel-item {
			// outline: 2px solid magenta;
			// outline-offset: -2px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		//height: 120px;
		width: 80%;						/* This (in with Carousel.js...spp) controls the padding between each item */
		max-width: 700px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		font-size: 1.4rem;
		& p {
			text-align: center;
			line-height: 1.8em;
			font-size: 1.4em;
			font-style: italic;
		}
		& h5 {
			font-weight: 700;
			font-family: sans-serif;
			font-size: 1.7em;
			letter-spacing: -.5px;
			margin: 5px 0 0 0;
		}
		& h6 {
			font-weight: 100;
			font-size: 1.3em;
			color: #666;
			margin: 5px 0 10px 0;
		}
		@media ($mobile-h) {
			font-size: 1rem;
			width: 95%;
			& p {
				line-height: 1.3em;
			}
		}


	}
	& button.BrainhubCarousel__arrows:hover:enabled,
	& button.BrainhubCarousel__arrows:hover,
	& button.BrainhubCarousel__arrows {
		background-color: unset;
		& span {
			padding: 8px;
			border-color: $dark-pink;
		}
		@media ($mobile-h) {
			padding: 8px;
			& span {
				padding: 3px;
			}
		}
	}
	& button.BrainhubCarousel__arrows:hover:enabled,
	& button.BrainhubCarousel__arrows:hover{
		& span {
			border-color: $solid-gold;
		}
	}
}

