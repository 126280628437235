@import "../../styles/variables.scss";
@import "../../styles/media.scss";


.footer {
	// & * {
	// 	outline: magenta solid 1px;
	// 	outline-offset: -2px;
	// }
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-height: 30vh;
	background-color: $dark-slate-grey;
	& .container {
		justify-content: flex-start;
		color: #FFFFFF;
		& .container-right {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			padding-top: 30px;
			& div.footer-contact-box {
					// outline: lime solid 1px;
					// outline-offset: -2px;
				display: flex;
				flex-direction: column;
				flex: 3 1 auto;
				align-items: flex-start;
			}
			& div.footer-links-box {
					// outline: cyan solid 1px;
					// outline-offset: -2px;
				display: flex;
				flex-direction: column;
				flex: 5 1 auto;
			}
			@media ($mobile-h) {
				flex-direction: column;
				padding-top: 10px;
				& div.footer-links-box {
					margin-top: 30px;
				}
			}
		}
		@media ($desktop-ms) {
			flex-direction: column;
			align-items: center;
		}
	}
	& h5 {
		font-weight: 200;
		font-size: 1.1em;
		letter-spacing: -.5px;
		margin: 5px 0 10px 0;
		text-transform: uppercase;
		line-height: 1.1em;
		&.business-details {
			margin-top: 15px;
			margin-bottom: 3px;
			& span.subtle {
				opacity: 0.6;
			}
		}
	}
	& p {
		color: #AAA;
		line-height: 1.4em;
	}
	& ul {
		list-style: none;
		padding-left: 10px;
		margin-top: 5px;
		& li {
			margin-bottom: 10px;
			& a {
				display: flex;
    			//align-items: flex-end;
				color: #AAA;
				text-decoration: none;
				& .icon {
					margin-right: 5px;
					fill: #AAA;
				}
				&:hover {
					color: #FFF;
					fill: #FFF;
					text-decoration: underline;
					& .icon {
						fill: #FFF;
					}
				}
			}
		}
	}
	& .disclaimer {
		display: inline;
		// flex-flow: wrap;
		// justify-content: space-around;
		font-size: 1.2rem;
		line-height: 1.2rem;
		& p {
			display: inline;
			margin-right: 10px;
			& a,
			& a:hover,
			& a:visited {
				color: #AAA;
			}
		}
	}
}

.footer-bg--city {

	background-image: url('../../img/stephen-woolley-occupation-therapy-footer-background.jpg');
	// @media ($desktop-l) {
	// 	background-image: url('../../img/stephen-woolley-occupation-therapy-footer-background--desktop-l.jpg');
	// }
	// @media ($desktop-s) {
	// 	background-image: url('../../img/stephen-woolley-occupation-therapy-footer-background--desktop-s.jpg');
	// }
	@media ($mobile-l) {
		background-image: url('../../img/stephen-woolley-occupation-therapy-footer-background--mobile-l.jpg');
	}

	background-blend-mode: multiply;
	background-position: 50% 20%;
	background-size: cover;
	@supports (-ms-ime-align:auto) {										/* If IE Edge (which does not support 'background-blend-mode'... */
		background-image: url('../../img/footer-bg-04-IE-Edge.jpg');
	}
}

.divider-up--top {
	background-size: 100% 100px;
	height: 100px;
	display: flex;
	width: 100%;
	@supports (-ms-ime-align:auto) {										/* If IE Edge (which does not support 'background-blend-mode'... */
		margin-top:	-1px;
	}

}


