@import "../../styles/variables.scss";
@import "../../styles/media.scss";


.about-us-img {

	background-image: url('../../img/about-stephen-woolley-occupational-therapist-melbourne.jpg');
	background-size: cover;
	background-position: 50% 0;
	@media ($tablet) {
		min-height: 250px;
	}
}