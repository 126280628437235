@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.gm-style .gm-style-iw-c  {
	border-radius: 3px;
	padding: 15px;
	font-size: 1.4rem;
	& h2 {
		font-weight: normal;
		line-height: unset;
		font-size: 1.6em;
		margin: 3px;
		font-family: Montserrat, san-serif;
		color: $dark-pink;
	}
	& h3 {
		font-weight: normal;
		color: $blue-slate;
		line-height: unset;
		font-size: 0.8em;
		margin: 0 0 3px 0;
		font-family: Montserrat, san-serif;
	}
	& p {
		margin: 2px 0 2px 10px;
		text-align: left;
		@media ($mobile-l) {
			font-size: 0.9em;
		}
	}
	& .gmap-icon-text-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 9px 3px;
		& .icon {
			fill: $dark-pink;
		}
		& a, & a:visited {
			color: $solid-plum;
			text-decoration: none;
		}
		& a:hover {
			color: $solid-gold;
			text-decoration: underline;
		}
	}
}

// .unused {
// 	color: #444444;
// }