@import "../../styles/variables.scss";
@import "../../styles/media.scss";

a.button-link {
	display: flex;
	align-items: center;
	padding: .6em 2.2em .6em 1.8em;
	margin: 5px;
	font-size: 1.7rem;
	border-radius: 3px;
	& div.icon {
		margin-right: 8px;
	}

	/* Default Colours raspberry, white, gold hover */
	background-color: $dark-pink;
	color: #FFF;
	& div.icon {
		fill: #FFF;
	}
	&:hover {
		background-color: $solid-gold;
		color: #FFF;
		& div.icon {
			fill: #FFF;
		}
	}
	&:visited {
		color: #FFF;
	}

	/* 	REVersed with colour icon hover
		bg: alpha white, txt: white, bgH:white, txtH: dark grey, iconH: colour(default gold)*/
	&.rev {
		background-color: $alpha-white-02;
		color: #FFF;
		& div.icon {
			fill: #FFF;
		}
		&:hover {
			color: #333;
			background-color: $solid-white;
			& div.icon {
				fill: $solid-gold;
				& .fa-linkedin {
					fill: #0072b1;
				}
			}
		}
	}

	// &.rev-alt {
	// 	/* Simple reversed colours: alpha-White, black, grey hover */
	// 	background-color: $alpha-white-06;
	// 	color: #000;
	// 	& div.icon {
	// 		fill: #000;
	// 	}
	// 	&:hover {
	// 		//color: $dark-pink;
	// 		background-color: $alpha-white-08;
	// 		& div.icon {
	// 			fill: #000;
	// 		}
	// 	}
	// 	&:visited {
	// 		color: #000;
	// 	}
	// }



}