@import "../../styles/variables.scss";
@import "../../styles/media.scss";


/* NAV STYLES - static desktop navigation menu */
.nav-wrap {
	display: flex;
	flex: 1 1 auto;
	z-index: 1;
	display: flex;
	@media ($nav-burger) {
		display: none;
	}
	.nav-menu {
		padding: 0;
		//margin-top: 20px;		/* SEE: Menu-Items-Vertical-Position */
		align-self: center; 	/* SEE: Menu-Items-Vertical-Position - aligns menu items to the middle vertically in the header */
		height: $nav-height;
		min-width: 250px;
		//overflow: hidden;
		display: flex;
		flex: 1 1 auto;
		justify-content: center;
		transition: margin-top 1s ease, padding 1s ease, height 1s ease;
		width: 100%; 							// required for mobile to utilise 100% via MQ in .menu-wrap .menu > div
		.header--small & {
			//margin-top: 7px;		/* SEE: Menu-Items-Vertical-Position */
			height: $nav-height--small;
			& > div > div > ul > li {
				font-size: 1.4rem;
				color: $solid-white;
				transition: color 1s ease;
				& ul > li {
					color: $solid-black;
				}
				//padding: 50% 10px; 	=>	 don't do this. Results in janky UX
			}
			& > div > div > ul > li:hover {
				background-color: $alpha-white-02;
			}
			& > div > div > ul > li.active {
				background: rgba(100,100,100,.15);
			}
			& > div > div > ul > li:hover > a {
				color: $bright-gold;
			}
			& > div {
				max-width: calc(#{$page-max-width} + 20px); 	// make wider to allow for padding (button type hover0 but still have first label left algin with logo
			}
		}
		& > div {
			display: flex;
			flex: 1 1 auto;
			align-items: center;
			transition: all 0.4s ease;
			& > div {
				display: flex;
				justify-content: flex-end;
				flex: 1 1 auto;
				transition: opacity 0.4s ease;
				& > ul {
					display: flex;
					flex: 1 1 auto;
					justify-content: flex-end;
					list-style: none;
					padding: 0;
					max-width: 600px;
				}
				& > ul > li {
					font-size: 1.4rem;
					transition: font-size 1s ease, padding 1s ease, color 1s ease;
					padding: 10px 0;									// adjust with nav height
					display: inline-block;
					position: relative;
					z-index: 100;
					border-radius: 3px;
					margin-left: 10px;

					& a {
						color: inherit;
						text-decoration: none;
						padding: 20px 15px;
						white-space: nowrap;
					}
					&:hover > a {
						color: $dark-pink;
						text-decoration: none;
						transition: color 0.4s ease;
					}
					& > ul.sub-menu {
						position: absolute;
						list-style: none;
						display: none;
						z-index: 100;
						background-color: $solid-white;
						border-radius: 3px;
						box-shadow: 0 13px 15px -10px rgba(0,0,0,.2), 0 0 45px rgba(0,0,0,.1);
						padding: 20px;
					}
				}
				& > ul > li.active {
					background: rgba(100,100,100,.05);
				}
				& > ul > li:hover {
					background-color: $alpha-pink;
					& > ul.sub-menu {
						display: block;
						top: 45px;
						left: 0px;
						position: absolute;
						z-index: 10;
						& li {
							padding: 10px 0;
							& a {
								color: inherit;
								text-decoration: none;
								transition: color 0.4s ease;
								&:hover {
									color: $bold-pink;
								}
							}
							&.active a {
								color: $bold-pink;
							}
						}
					}
				}
			}
		}
	}
}
/*
& > ul.sub-menu {
	list-style: none;
	display: none;
}
&.active {
	color: $bright-gold;
	& > ul.sub-menu {
		list-style: none;
		display: inline;
		& li > a {
			color: inherit;
			text-decoration: none;
			transition: color 0.4s ease;
			margin-left: 15px;
		}
	}
}
*/