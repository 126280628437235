@import "../../styles/variables.scss";
@import "../../styles/media.scss";

	/*	Complex triggers of SCROLLING and PAGE-WIDTH affect main top margin (now chnaged ot affecting banner height).
		This was part of .main. See #16uxza - Fixes page banner to extend under header

			SCREEN		  SCROLL	  HEADER	  NAV-BAR	: MAIN-HEIGHT
		1	Desktop 	: No		: Normal	: Normal	: header-height + nav-height
		2	Desktop		: Yes		: Small		: Small		: header-height + nav-height - nav-height--small
		3	Thin 		: No		: Normal	: None		: header-height
		4	Thin		: Yes		: Small		: None		: header-height + nav-height - nav-height--small
		5	Mobile		: Both		: Small		: None		: header-height--small

		1 & 4. Desktop scroll & Thin page scroll
		NOTE ONLY ADJUST BY DIFF IN NAV BAR HEIGHTS.
		We don't reduce the main by the header height as it
		would result in skip of content while scrolling.

		5. Mobile no-scroll
		Always has small menu and no nav-bar regardless of
		scrolling so always set main top margin to small header height.

		The change to controling banner heigt instead of top-margin allows for billboard to extend under header.
		This eliminates the white gap when header resizes.

	*/

.section-pusher {					/* Used to push banner down to be underneath info panel when on very narrow screens */
	display: none;
	@media ($mobile-l) {
		display: flex;
		flex: 1 1 auto;
		height: 200px;
		background-color: $teaser-column-fill; // allows for the switch of BG colour when screen narrows to trigger column. See .divider-down--top &--svg
	}
}
.banner {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	&--blank {
		flex: 0 0 auto;
		height: $main-top-normal;
		.header--small & {
			@media ($desktop-s) {
				height: $main-top-small;
			}
			@media ($mobile-l) {
				height: $main-top-mobile;
			}
		}
	}
	&--billboard {					/* Default Billboard styles */
		position: relative;
		background-size: cover;
		z-index: 1;
		height: calc(480px + #{$main-top-normal});
		padding-top: $main-top-normal;
		.header--small & {
			@media ($desktop-s) {
				height: calc(480px + #{$main-top-small});
				padding-top: $main-top-small;
			}
			@media ($mobile-l) {
				height: calc(480px + #{$main-top-mobile});
				padding-top: $main-top-mobile;
			}
		}
	}
}

.teaser-box {
	display: flex;
	position: absolute;
	z-index: 5;
	align-items: flex-start;
	right: 50%;
	top: calc( 40px + #{$header-height} );
	@media ($mobile-l) {
		align-items: center;
		top: calc( 30px + #{$header-height--small} );
		left: 0;
		width: 100%;
	}
	& .teaser {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		text-align: right;
		@media ($mobile-l) {
			align-items: center;
			text-align: center;
		}
	}
	& h2 {
		font-weight: 100;
		text-transform: uppercase;
		font-size: 3.3rem;
		color:$solid-black;
		margin: 0;
	}
	& h3 {
		font-weight: 100;
		text-transform: uppercase;
		font-size: 4rem;
		color:$dark-pink;
		margin: 18px 0px 14px 0px;
	}
	& p {
		font-family: 'Montserrat', sans-serif;
		color: #333333;
		font-size: 2.6rem;
		font-weight: 300;
		line-height: 1.2em;
		margin: 0 0 1.5em 0;
		max-width: 80vw;
	}
	span.forced {
		display: block;
	}
	@media ($desktop-m) {
		right: 45%;
	}
	@media ($desktop-s) {
		right: 45%;
		& h3 {	font-size: 3.6rem;	}
		& p {	font-size: 2.3rem;	}
	}
	@media ($tablet) {
		right: 40%;
		& h2 {	font-size: 2.4rem;	}
		& h3 {	font-size: 2.9rem;	}
		& p {	font-size: 1.8rem;	}
	}
	@media ($mobile-h) {
		right: 35%;
		& h2 {	font-size: 2.3rem;	}
		& h3 {	font-size: 2.6rem;	}
		& p {	font-size: 1.6rem;	}
	}
	@media ($mobile-l) {
		flex-direction: column;
		& h3 {
			margin: .2em 0;
		}
	}
	@media ($mobile-m) {
		& h2 {	font-size: 1.9rem;	}
		& h3 {	font-size: 2.2rem;	}
		& p {	font-size: 1.4rem;	}
		& span.forced {
			display: inline;
			&::after {
				content: " ";
			}
		}
	}
}
.billboard--expert-right {
	background-position: 50% 20%;
	background-image: url('../../img/medico-legal-occupational-therapy-reports-banner.jpg');
	@media ($desktop-l) {
		background-image: url('../../img/medico-legal-occupational-therapy-reports-banner--desktop-l.jpg');
	}
	@media ($desktop-s) {
		background-position: 60% 20%;
		background-image: url('../../img/medico-legal-occupational-therapy-reports-banner--desktop-s.jpg');
	}
	@media ($mobile-l) {
		background-position: 80% 20%;
		background-image: url('../../img/medico-legal-occupational-therapy-reports-banner--mobile-l.jpg');
	}
}
div.floater {
	// outline: solid gold 1px;
	// outline-offset: -1px;
	max-width: 425px;
	width: 45vw;
	height: 100%;
	background-image: url('../../img/Stephen-Woolley-Profile-Float.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
	position: absolute;
	right: 0;
	bottom: 0;
	margin-right: 10vw;
	@media ($desktop-m) {
		margin-right: 5vw;
		// outline: solid magenta 1px;
	}
	@media ($desktop-s) {
		margin-right: 0.5vw;
		max-width: 100vw;
		// outline: solid green 1px;
	}
	@media ($mobile-l) {
		max-width: 420px;
		width: 100vw;
		//min-width: 480px;
		height: 420px;
		// outline: solid red 1px;
		background-size: cover;
		background-position: center bottom;
	}
}
.divider-down--top {
	background-size: 100% 100px;
	height: 100px;
	display: none;
	width: 100%;
	position: absolute;
	z-index: 5;
	top: 0;
	@media ($mobile-l) {
		display: flex;
	}
	&--svg {
		fill: $teaser-column-fill; // allows for the switch of BG colour when screen narrows to trigger column. See .section-pusher
	}
}

.divider-down--bottom {
	background-size: 100% 100px;
	height: 100px;
	display: flex;
	width: 100%;
	position: absolute;
	z-index: 5;
	bottom: -1px;
}


