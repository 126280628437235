@import '../../styles/variables.scss';
@import "../../styles/media.scss";

form {
	font-size: 1.4rem;
}
.input-row--group {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	justify-content: space-between;
	@media ($mobile-l) {
		flex-direction: column;
	}
}
.input-row {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 11px;
	& label {
		font-weight: 600;
		&.required {
			&::after {
				content: '*';
				font-weight: 600;
				color: $warning-text;
				margin-left: 4px;
				font-size: 1.2em;
				line-height: 1.2em;
			}
		}
	}
	& input[type="text"],
	& input[type="email"],
	& input[type="password"],
	& textarea {
		// display: flex;
		// flex: 1 1 auto;
		border: solid 1px #CCC;
		font-size: 1em;
		line-height: 1.3em;
		padding: 12px 14px;
		width: 95%;
		.input-row--group & {
			width: 90%;
			@media ($mobile-l) {
				width: 95%;
			}
		}
		&::placeholder {
			color: #BBB;
		}
		&:focus {
			outline: solid 1px $solid-sky;
			outline-offset: -1px;
		}
		&.has-error {
			outline: solid 1px $warning-text;
			outline-offset: -1px;
		}
	}

	& textarea {
		font-family: sans-serif;
		resize: none;
	}
	& button[type="submit"] {
		font-family: sans-serif;
		border: 0;
		background: none;
		border-radius: 4px;
		padding: 0.7em 1.5em;
		box-shadow: none;
		font-size: 1.1em;
		color: #FFF;
		cursor: pointer;
		background: $solid-gold;
		&:hover {
			background: $dark-pink;
		}
		&[disabled=disabled],
		&:disabled {
			background-color: #999;
		}
	}
}
.form-submit-result-message {
	display: none;
	margin-top: 10px;
	padding: 20px;
	width: 95%;
	&.submit-success {
		display: flex;
		background-color: rgb(197, 250, 184);
		border: solid 1px rgb(60, 110, 47);
		color: solid 1px rgb(60, 110, 47);
	}
	&.submit-fail {
		display: flex;
		background-color: rgb(255, 199, 199);
		border: solid 1px rgb(128, 48, 48);
		color: solid 1px rgb(128, 48, 48);
	}
}


.form-control {
    font-size: 1.4rem;
	line-height: 1.8;
	padding: 8px 10px;
	width: 95%;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url(../../svg/icon-arrow-down.svg) 98% / 19px no-repeat #FAFAFA;
	cursor: pointer;
}
select:focus {
	background: url(../../svg/icon-arrow-up.svg) 98% / 19px no-repeat #FAFAFA;
}

/* CAUTION: Internet Explorer hackery ahead */
select::-ms-expand {
	display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
	select {
		background: none\9;
		padding: 5px\9;
	}
}

.input-row button[type="submit"].disabledButton, .disabledButton {
	background-color: $light-grey;
}