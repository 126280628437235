@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.icon {
	fill: #FFF;
	width: 26px;
	height: 26px;
	& .svg-inline--fa {			// adjust icons from FontAwesome
		width: 21px;
	}

	/* self-colour and self-hover classNames
		These can be passed into <Icon /> to allow the specific colour for a given icon to be used.
		Most useful for brand icons such as LinkedIn and Facebook ect.
	*/

	&.self-colour {
		&.type-linked-in {				fill: #0072b1!important;	}
		&.type-facebook {				fill: #3b5998 !important;	}
	}
	&.self-hover {
		&:hover {
			&.type-linked-in {			fill: #0072b1!important;	}
			&.type-facebook {			fill: #3b5998!important;	}
		}
	}
}

