@import '../../styles/variables.scss';
@import "../../styles/media.scss";


.form-field-message-box {
	font-size: .8em;
	color: #bbb;
	padding-left: 3px;
	&.error {
		color: $warning-text;
	}
}