@import "../styles/variables.scss";
@import "../styles/media.scss";

html {
	height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
	line-height: 1.6;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	height: 100%; /* REFACTOR : removed on PRO */
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	position: relative;
	font-size: 1.4rem;
}

.main {
	text-align: center;
	display: flex;
	flex-direction: column;
	min-height: calc(70vh);
	/* 'Complex triggers of SCROLLING and PAGE-WIDTH'
		used to be a margin here but moved to height of banner in banner.scss
		*/
}


.brand-logo {
	.size-100 & {
		height: 100px;
	}
}


/* GLOBAL */
.pt-0 	{	padding-top: 0;		}
.pt-10 	{	padding-top: 10px;	}
.mt-0 	{	margin-top: 0;		}
.mt-10 	{	margin-top: 10px;	}
.mt-20 	{	margin-top: 20px;	}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
	line-height: 1.1em;
}
section {
	display: flex;
	flex-direction: column;
	padding: 40px 0;
	&.collapse {
		padding-bottom: 0;
		& + section {
			padding-top: 0;
		}
	}
	&.key-bg {
		background-color: $dark-pink;
	}
	&.solid-gold-bg {
		background-color: $solid-gold;
	}
	&.blue-slate-bg {
		background-color: $blue-slate;
	}
	&.neutral-dark-bg {
		background-color: $slate-grey;
	}
	&.neutral-light-bg {
		background-color: $light-grey;
	}
	&.neutral-ultra-light-bg {
		background-color: $ultra-light-grey;
	}
	&.white-bg {
		background-color: $solid-white;
	}
	&.soft-sky-bg {
		background-color: $soft-sky;
	}
	& .section-heading-box {
		&.bound {
			max-width: $container-max-width;
			margin: 0 auto;
		}
			// outline: solid 2px rgb(255, 0, 255);
			// outline-offset: -2px;
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 3rem;
		padding-left: 40px;
		padding-right: 40px;
		& h1 {
			font-weight: 700;
			font-family: sans-serif;
			font-size: 1.4em;
			letter-spacing: -.5px;
			margin: 1.1em 0 .2em 0;
		}
		& h2 {
			font-weight: 400;
			text-transform: uppercase;
			color: $mid-grey;
			font-size: 0.6em;
			letter-spacing: -.2px;
			margin: 0 0 .4em 0;
			& span {
				font-weight: 700;
			}
			&.bold-pink {
				color: $bold-pink;
			}
		}
		& h3 {
			font-weight: 700;
			font-family: sans-serif;
			font-size: 1em;
			letter-spacing: -.5px;
			margin: 30px 0 .4em 0;
		}
		& h4 {
			font-weight: 100;
			font-size: 0.55em;
			text-transform: uppercase;
			color:$dark-pink;
			margin: 0px 0px 20px 0px;
			& span {
				font-weight: 700;
			}
		}
		@media ($desktop-m) {
			font-size: 2.4rem;
		}
		@media ($tablet) {
			font-size: 2.2rem;
		}

	}
}
.container {
	text-align: left;
    max-width: $container-max-width;
    width: 100%;
    padding: 0;
    margin: 0 auto;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	&.with-box-heading {
		padding-left: 40px;
		padding-right: 40px;
	}
	& h1 {
		font-weight: 700;
		margin: 0.2em 0 0.6em 0;
		font-size: 2.4rem;
    	letter-spacing: -1px;
		color:$warm-black;
		line-height: 1.2;
		font-family: sans-serif;
	}
	& h2 {
		font-weight: 700;
		margin: 0 0 0 0;
		font-size: 1.5rem;
    	letter-spacing: -.5px;
		color:$warm-black;
		line-height: 1.2;
	}
	& h3 {
		font-weight: bold;
		margin: 0 0 .4rem 0;
    	letter-spacing: -.5px;
	}
	& h4 {
		font-weight: 100;
		text-transform: uppercase;
		font-size: 1.6rem;
		color:$dark-pink;
		margin: 0px;
		& span {
			font-weight: 700;
		}
		&.breaker {
			margin-top: 7px;			/* brings first header inline with p text in next column. */
			margin-bottom: 8px;
			&:not(:first-child) {
				margin-top: 30px;		/* non-first headers need more top margin from last element (usually p) */
			}
		}
	}
	& p,
	& ul > li {
		color: #707070;
		margin: 0.2em 0 0.5em 0;
		line-height: 1.9;
		font-family: "Open Sans",sans-serif;
		&:first-child {
			margin-top: 0;
		}
	}
	& ul > li {
		line-height: 1.3;
	}
	& a {
		font-size: 1.0em;
		line-height: 1.9em;
		text-decoration: none;
		color: $dark-pink;
		cursor: pointer;
		&:hover {
			color: $solid-gold;
			text-decoration: underline;
		}
	}



	@media ($desktop-l) {
		padding: 0 40px; /* adds edge padding as vp narrows */
		& h1 {
			font-size: 2.3rem;
		}
	}
	@media ($tablet) {
		flex-direction: column;
		align-items: center;
	}



	.key-bg & h1,
	.key-bg & h2,
	.key-bg & h3,
	.key-bg & h4,
	.key-bg & p,
	.key-bg & a,
	.key-bg & a:visited {
		color: #FFFFFF;
	}
	.key-bg & p,
	.key-bg & a,
	.key-bg & a:visited {
		opacity: 0.95;
	}
	.key-bg & h4 {
		opacity: 0.7;
	}
	.key-bg & a:hover {
		color: $bright-gold;
		text-decoration: underline;
	}
}
.container-left,
.container-right {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 50%;
	margin: 40px 12px 30px 0;
	@media ($desktop-s) {		/* prevents fully columnised content spreading to edge of vp */
		max-width: 70vw;
		width: 100%;
	}
}
.container-right {
	margin: 40px 0 30px 12px;
}
@media ($tablet) {
	.container-left,
	.container-right {
		margin: 40px 0 30px 0;
	}
}



section.pad-top-50 {
	margin-top: 50px;
}
section.pad-base-80 {
	margin-bottom: 80px;
}

section.marker {
	color: #E0E0E0;
	@media ($wide){				div:after { content: 'wide'; }				}
	@media ($desktop-l){		div:after { content: 'desktop-l'; }			}
	@media ($desktop-m){		div:after { content: 'desktop-m'; }			}
	@media ($desktop-ms){		div:after { content: 'desktop-ms'; }		}
	@media ($desktop-s){		div:after { content: 'desktop-s'; }			}
	@media ($tablet){			div:after { content: 'tablet'; }			}
	@media ($mobile-landscape){	div:after { content: 'mobile-landscape'; }	}
	@media ($mobile-h){			div:after { content: 'mobile-h'; }			}
	@media ($mobile-l){			div:after { content: 'mobile-l'; }			}
	@media ($mobile-m){			div:after { content: 'mobile-m'; }			}
	@media ($mobile-s){			div:after { content: 'mobile-s'; }			}
}
