@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.header {
	display: flex;
	justify-content: center;
	height: $header-height;
	transition: border-bottom $default-ease ease,
				height $default-ease ease,
				box-shadow $default-ease ease,
				padding $default-ease ease,
				background-color $default-ease ease,
				fill $default-ease ease;
	padding: 10px 15px;
	position:fixed;
	width: 100%;
	background-color: #FFFFFF;
	color: #333;
	border-bottom:solid 2px $bold-pink;
	top:0px;
	z-index: 100;
	.header--small & {
		height: $header-height--small;
		padding: 6px 10px 2px 10px;
		background-color: $lift-pink;
		border-bottom:solid 1px $solid-white;
	}
}
.header--shadow {
	box-shadow: 0 13px 15px -10px rgba(0,0,0,.2);
	// USED FOR FULL-BAR NAV MENUS
	// & .nav-menu {
	// 	box-shadow: 0 13px 15px -10px rgba(0,0,0,.2);
	// }
}
.header-box {
	display: flex;
	justify-content: flex-start;
	flex: 1 1 auto;
	max-width: $page-max-width;
	align-items: center;
}
.header-item {
	display: flex;
	flex: 1 1 auto;
	height: 100%;
}
.header-item--logo {
	flex: 0 1 auto;
	& > a,
	& > a:visited {
		text-decoration: none;
	}
}
.header-item-menu {
	justify-content: flex-end;
}





