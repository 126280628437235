@import "../../styles/variables.scss";
@import "../../styles/media.scss";


.home-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}


.philosophy-img {

	background-image: url('../../img/consult-v01.jpg');
	background-size: cover;
	background-position: 50% 0;
	@media ($tablet) {
		min-height: 250px;
	}
}
.philosophy-txt {
	padding-left: 20px;
	@media ($tablet) {
		padding-left: 0px;
	}
}

section .section-heading-box p {
	font-size: 1.4rem;
	& a {
		text-decoration: none;
		color: currentColor;
		&:hover {
			text-decoration: underline;
			color: $dark-pink;
		}
	}
}