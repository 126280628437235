@import "../../styles/variables.scss";
//@import "../../styles/media.scss";

.scroll-to-top-button {
	opacity: 0.3;
	background-color: $dark-pink;
	width: 40px;
	height: 40px;
	position: fixed;
    cursor: pointer;
	bottom: 10px;
	right: 10px;
	border-radius: 20px;
	border: none;
    transition: opacity 0.5s ease-in-out 0s, right;
	opacity: 0;
	z-index: 6;
	.page--scrolled &:hover {		/* Mobile devices have sticky hover after a click event. Consider mondernizr lib to counteract if it becomes an issue */
		opacity: 1;
	}
	.page--scrolled & {
		opacity: 0.3;
	}
	& .scroll-to-top--svg {
		fill: white;
	}
}